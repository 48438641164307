<template>
	<div class="d-flex flex-column flex-root">
		<div
			class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
			:class="{
				'login-signin-on': this.state == 'signin',
				'login-forgot-on': this.state == 'forgot',
			}"
			id="kt_login"
		>
			<!--begin::Aside-->
			<div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #f2c98a">
				<div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
					<a href="#" class="text-center mb-10">
						<img src="media/logos/logo-genic.png" class="max-h-70px" alt="" />
					</a>
					<h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923">
						Discover Amazing Genic Drive <br />with great build tools
					</h3>
				</div>
				<div
					class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
					:style="{ backgroundImage: `url(${backgroundImage})` }"
				></div>
			</div>
			<!--begin::Aside-->
			<!--begin::Content-->
			<div
				class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
			>
				<div class="d-flex flex-column-fluid flex-center">
					<!--begin::Signin-->
					<div class="login-form login-signin">
						<div v-if="false" class="alert alert-danger alert-dismissible fade show" role="alert">
							Your account has been locked due to 3 failed attempts.<br />
							Please try again after 24 hours
						</div>
						<div v-if="oauthx == 'success'" class="pb-5">
							<div class="alert alert-success alert-dismissible fade show" role="alert">
								Success! Please login with your new password.
							</div>
						</div>
						<div v-if="is2FAAuth">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Two-factor authentication
								</h3>
							</div>
							<div class="form-group">
								<label class="font-size-h6 font-weight-bolder text-dark"
									>Enter One-Time Password (OTP) for two-factor authentication below we send to
									{{ is2FA.email }}</label
								>
								<v-otp-input
									class="otp-input mt-8"
									type="number"
									hide-details
									:disabled="isLoading"
									:loading="isLoading"
									length="6"
									v-model="is2FA.otp"
									@finish="submit2FA()"
								></v-otp-input>
								<div class="text-right mt-4 font-size-h7">
									Don't receive the OTP?
									<a
										class="text-primary font-weight-bolder text-hover-primary cursor-pointer pt-5"
										id="kt_login_forgot"
										:class="{ 'pointer-none ele-disabled': isLoading }"
										@click="submitLogin()"
										>Resend OTP
									</a>
								</div>
							</div>
							<div class="pb-lg-0 pb-5">
								<button
									:disabled="isLoading"
									type="button"
									v-on:click="submit2FA()"
									ref="kt_login_signin_submit"
									class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
								>
									Verify & Proceed
								</button>
							</div>
						</div>
						<form v-else class="form" novalidate="novalidate" id="kt_login_signin_form">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Welcome to Genic Drive
								</h3>
							</div>
							<div class="form-group">
								<label class="font-size-h6 font-weight-bolder text-dark">Email</label>
								<div id="example-input-group-1" label="" label-for="example-input-1">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="text"
										name="email"
										ref="email"
										:disabled="isLoading"
										v-model="form.email"
									/>
								</div>
							</div>
							<div class="form-group">
								<div class="d-flex justify-content-between mt-n5">
									<label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
									<a
										class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
										id="kt_login_forgot"
										@click="showForm('forgot')"
										>Forgot Password ?
									</a>
								</div>
								<div id="example-input-group-2" label="" label-for="example-input-2">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="password"
										name="password"
										ref="password"
										:disabled="isLoading"
										v-model="form.password"
										autocomplete="off"
										v-on:keyup.enter="submitLogin()"
									/>
								</div>
							</div>
							<div class="pb-lg-0 pb-5">
								<button
									:disabled="isLoading"
									type="button"
									v-on:click="submitLogin()"
									ref="kt_login_signin_submit"
									class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
								>
									Sign In
								</button>
							</div>
						</form>
					</div>
					<!--end::Signin-->
					<!--begin::Forgot-->
					<div class="login-form login-forgot">
						<div v-if="forgotSuccess">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder font-size-h4 font-size-h1-lg text-success">Success !</h3>
								<p class="text-muted font-weight-bold font-size-h4">
									An email has been sent to <span class="font-weight-bolder">{{ forgotEmail }}</span> with
									instructions for resetting your password.
								</p>
								<p class="text-muted font-weight-bold font-size-h5">
									This email may take a few minutes to arrive in you inbox.
								</p>
							</div>
						</div>
						<div v-else>
							<!--begin::Form-->
							<form
								class="form"
								novalidate="novalidate"
								id="kt_login_forgot_form"
								ref="kt_login_forgot_form"
								v-on:submit.stop.prevent="submitForgot()"
							>
								<div class="pb-13 pt-lg-0 pt-5">
									<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
										Forgotten Password ?
									</h3>
									<p class="text-muted font-weight-bold font-size-h4">
										Enter the email address with your account and we'll send an email with confirmation to
										reset your password.
									</p>
								</div>
								<div class="form-group">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
										type="email"
										v-model="forgotEmail"
										:disabled="isLoading"
										placeholder="Email"
										name="email"
										autocomplete="off"
										v-on:keyup.enter="submitForgot()"
									/>
								</div>
								<div class="form-group d-flex flex-wrap pb-lg-0">
									<button
										type="button"
										:disabled="isLoading"
										v-on:click="submitForgot()"
										ref="kt_login_forgot_submit"
										class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
									>
										Submit
									</button>
									<button
										type="button"
										:disabled="isLoading"
										id="kt_login_forgot_cancel"
										class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
										@click="showForm('signin')"
									>
										Cancel
									</button>
								</div>
							</form>
						</div>
					</div>
					<!--end::Forgot-->
				</div>
				<!--begin::Content footer-->
				<div
					class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
				>
					<a
						:href="`https://www.genicsolutions.com/privacy-policy?utm=genic-drive-login-page&cdx=${$APP_CLIENT_NAME}`"
						target="_blank"
						class="text-primary font-weight-bolder font-size-h5"
						>Privacy Policy</a
					>
					<a
						:href="`https://www.genicsolutions.com/contact-us?utm=genic-drive-login-page&cdx=${$APP_CLIENT_NAME}`"
						target="_blank"
						class="text-primary ml-10 font-weight-bolder font-size-h5"
						>Contact Us</a
					>
				</div>
				<!--end::Content footer-->
			</div>
			<div class="btx-alert" v-if="errors.length">
				<div
					v-for="(row, index) in errors"
					:key="index"
					class="alert alert-custom alert-notice alert-light-danger fade show"
					role="alert"
				>
					<div class="alert-icon">
						<i class="flaticon-warning"></i>
					</div>
					<div class="alert-text">{{ row }}</div>
					<div class="alert-close">
						<button
							type="button"
							v-on:click="removeError(index)"
							class="close"
							data-dismiss="alert"
							aria-label="Close"
						>
							<span aria-hidden="true"><i class="ki ki-close"></i></span>
						</button>
					</div>
				</div>
			</div>

			<div class="btx-alert" v-if="twoFASent">
				<div class="alert alert-custom alert-notice alert-light-success fade show" role="alert">
					<div class="alert-icon">
						<i class="flaticon-warning"></i>
					</div>
					<div class="alert-text">One-Time Password (OTP) has been sent to your registered email.</div>
					<div class="alert-close">
						<button
							type="button"
							@click="twoFASent = false"
							class="close"
							data-dismiss="alert"
							aria-label="Close"
						>
							<span aria-hidden="true"><i class="ki ki-close"></i></span>
						</button>
					</div>
				</div>
			</div>
			<!--end::Content-->
		</div>
	</div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import { LOGIN, LOGOUT, FORGOT, TWO_FACTOR_LOGIN } from "@/core/services/store/auth.module";

export default {
	name: "login-1",
	data() {
		return {
			state: "signin",
			isLoading: false,
			is2FAAuth: false,
			twoFASent: false,
			retryCount: 0,
			is2FA: {
				token: null,
				email: null,
				expiry: null,
				otp: null,
			},
			errors: [],
			forgotEmail: null,
			oauthx: null,
			forgotSuccess: false,
			form: {
				email: null,
				password: null,
			},
		};
	},
	computed: {
		backgroundImage() {
			return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
		},
	},
	mounted() {
		this.oauthx = this.$route?.query?.oauthx;
		if (this.oauthx == "success") {
			this.form.email = this.$route.query.email;
		}
	},
	methods: {
		removeError(index) {
			this.errors.splice(index, 1);
		},
		submitForgot() {
			const _this = this;

			_this.errors = [];

			const forgotButton = _this.$refs["kt_login_forgot_submit"];

			forgotButton.classList.add("spinner", "spinner-light", "spinner-right");

			_this.isLoading = true;

			_this.forgotSuccess = false;

			_this.$store
				.dispatch(FORGOT, { email: this.forgotEmail })
				.then(() => {
					_this.forgotSuccess = true;
				})
				.catch((response) => {
					if (response.status === 429) {
						_this.errors = ["Too many requests. Please try again later."];
					} else {
						_this.errors = response.data?.data ?? [];
					}
				})
				.finally(() => {
					_this.isLoading = false;
					forgotButton.classList.remove("spinner", "spinner-light", "spinner-right");
				});
		},
		submit2FA() {
			const _this = this;

			_this.errors = [];

			_this.twoFASent = false;

			_this.$store.dispatch(LOGOUT);

			const submitButton = _this.$refs["kt_login_signin_submit"];

			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			_this.isLoading = true;

			_this.$store
				.dispatch(TWO_FACTOR_LOGIN, { ..._this.is2FA, ..._this.form })
				.then(() => {
					const resolved = _this.$router.resolve({
						name: "dashboard",
					});
					window.location.href = resolved.href;
				})
				.catch((response) => {
					_this.is2FA.otp = null;
					if (response.status === 429) {
						_this.errors = ["Too many requests. Please try again later."];
					} else {
						_this.errors = response.data?.data ?? [];
					}
				})
				.finally(() => {
					_this.isLoading = false;
					submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
				});
		},
		submitLogin() {
			const _this = this;

			_this.errors = [];

			_this.twoFASent = false;

			_this.$store.dispatch(LOGOUT);

			const submitButton = _this.$refs["kt_login_signin_submit"];

			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			_this.isLoading = true;

			_this.$store
				.dispatch(LOGIN, _this.form)
				.then((response) => {
					if (response?.type === "Bearer") {
						const resolved = _this.$router.resolve({
							name: "dashboard",
						});
						window.location.href = resolved.href;
					} else if (response?.type === "2FA") {
						_this.is2FAAuth = true;
						_this.twoFASent = true;
						_this.is2FA = {
							otp: null,
							token: response?.token,
							email: response?.email,
							expiry: response?.expiry,
						};
						setTimeout(() => {
							_this.twoFASent = false;
						}, 5000);
					}
				})
				.catch((response) => {
					if (response.status === 429) {
						_this.errors = ["Too many requests. Please try again later."];
					} else {
						_this.errors = response.data?.data ?? [];
					}
				})
				.finally(() => {
					_this.isLoading = false;
					submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
				});
		},
		showForm(form) {
			this.state = form;
			var form_name = "kt_login_" + form + "_form";
			KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
		},
	},
};
</script>
